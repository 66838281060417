import request from 'utils/request.utils';

export const getCategories = async () => {
  try {
    const response = await request({ withAuth: false }).get(`/product-category?type=all`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (e) {
    throw e.message;
  }
};
