import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';

const RightArrow = (props) => {
  const { onClick, container, circle, arrow, width, height } = props;

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className={container}>
      <button type="button" className={circle} onClick={onClick}>
        <ChevronRightIcon width={width} height={height} className={arrow} />
      </button>
    </div>
  );
};

const LeftArrow = (props) => {
  const { onClick, container, circle, arrow, width, height } = props;
  return (
    <div className={container}>
      <button type="button" className={circle} onClick={onClick}>
        <ChevronLeftIcon width={width} height={height} className={arrow} />
      </button>
    </div>
  );
};

export const bannerSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <RightArrow
      container="absolute top-[140px] right-5 z-10"
      circle="flex justify-center items-center w-10 h-10 bg-white/[0.4] rounded-full"
      arrow="text-white/[0.8]"
      height={20}
      weight={20}
    />
  ),
  prevArrow: (
    <LeftArrow
      container="absolute top-[140px] left-5 z-10"
      circle="flex justify-center items-center w-10 h-10 bg-white/[0.4] rounded-full"
      arrow="text-white/[0.8]"
      height={20}
      weight={20}
    />
  ),
};

export const exploreCategorySettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
};

export const recommendationSettings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: (
    <RightArrow
      container="absolute top-[70px] right-0 z-10"
      circle="flex justify-center items-center w-10 h-10 bg-red-gradient rounded-full"
      arrow="text-white"
      height={20}
      weight={20}
    />
  ),
  prevArrow: (
    <LeftArrow
      container="absolute top-[70px] -left-5 z-10"
      circle="flex justify-center items-center w-10 h-10 bg-red-gradient rounded-full"
      arrow="text-white"
      height={20}
      weight={20}
    />
  ),
};

export const productSettings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: (
    <RightArrow
      container="absolute top-[140px] -right-5 z-10"
      circle="flex justify-center items-center w-10 h-10 bg-red-gradient rounded-full"
      arrow="text-white/[0.8]"
      height={20}
      weight={20}
    />
  ),
  prevArrow: (
    <LeftArrow
      container="absolute top-[140px] -left-5 z-10"
      circle="flex justify-center items-center w-10 h-10 bg-red-gradient rounded-full"
      arrow="text-white/[0.8]"
      height={20}
      weight={20}
    />
  ),
};

export const productDetailSettings = {
  // customPaging: function (i) {
  //   return (
  //     <a>
  //       <img src={`/assets/illustrations/product-detail01.jpg`} />
  //       {i}
  //     </a>
  //   );
  // },
  dots: true,
  dotsClass: 'slick-dots slick-thumb',
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <RightArrow
      container="absolute -bottom-[60px] right-0 z-10"
      circle="flex justify-center items-center w-8 h-8 bg-red-gradient rounded-full"
      arrow="text-white/[0.8]"
      height={20}
      weight={20}
    />
  ),
  prevArrow: (
    <LeftArrow
      container="absolute -bottom-[60px] left-0 z-10"
      circle="flex justify-center items-center w-8 h-8 bg-red-gradient rounded-full"
      arrow="text-white/[0.8]"
      height={20}
      weight={20}
    />
  ),
};

export const relatedProductSettings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: (
    <RightArrow
      container="absolute -top-[60px] right-0 z-10"
      circle="flex justify-center items-center w-8 h-8 bg-red-gradient rounded-full"
      arrow="text-white/[0.8]"
      height={20}
      weight={20}
    />
  ),
  prevArrow: (
    <LeftArrow
      container="absolute -top-[60px] right-[45px] z-10"
      circle="flex justify-center items-center w-8 h-8 bg-red-gradient rounded-full"
      arrow="text-white/[0.8]"
      height={20}
      weight={20}
    />
  ),
};

export const categoryCarouselSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: (
    <RightArrow
      container="absolute top-[35px] right-0 z-10"
      circle="flex justify-center items-center w-6 h-6 bg-red-gradient rounded-full"
      arrow="text-white"
      height={12}
      weight={12}
      c
    />
  ),
  prevArrow: (
    <LeftArrow
      container="absolute top-[35px] -left-[10px] z-10"
      circle="flex justify-center items-center w-6 h-6 bg-red-gradient rounded-full"
      arrow="text-white"
      height={12}
      weight={12}
    />
  ),
};
