/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
import Image from 'next/image';
import Slider from 'react-slick';
import Link from 'next/link';
import { Tab } from '@headlessui/react';
import { ArchiveIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { currencyFormatter } from 'global/utils/currency.utils';
import {
  bannerSettings,
  exploreCategorySettings,
  recommendationSettings,
  productSettings,
  productDetailSettings,
  relatedProductSettings,
  categoryCarouselSettings,
} from './settings';

// Import Slick styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const BannerCarousel = ({ banners }) => (
  <>
    <Slider {...bannerSettings}>
      {banners?.content?.banners.map((banner) => (
        <div key={banner.sortIndex} className="overflow-hidden rounded-[20px]">
          <a href={banner.image.redirectUrl ?? '/'}>
            <div
              className=" h-[384px] min-w-[900px] bg-no-repeat bg-cover rounded-[20px]"
              style={{
                backgroundImage: `url(${banner.image.path})`,
                backgroundSize: 'cover',
              }}
            />
          </a>
        </div>
      ))}
    </Slider>
  </>
);

export const RecommendationCarousel = ({ recomendationBanners }) => (
  <div className="block recommendation-carousel">
    <Slider {...recommendationSettings}>
      {recomendationBanners?.content?.banners.map((banner) => (
        <div key={banner?.sortIndex} className="flex w-full h-full relative">
          <a href={banner?.redirectUrl ?? ''}>
            <img
              src={banner?.image?.path}
              alt={banner?.image?.seo?.alt}
              width="280px"
              height="180px"
              layout="fixed"
            />
          </a>
        </div>
      ))}
    </Slider>
    <style jsx>{`
      .recommendation-carousel .slick-slide {
        margin: '0 -25px';
      }
    `}</style>
  </div>
);

export const ProductCarousel = ({ products }) => (
  <div className="block">
    <Slider {...productSettings}>
      {products?.map((product, index) => (
        <div key={index} className={`${index === 0 ? 'h-[360px] px-1' : 'px-1'} `}>
          <Link href={`/product/${product?.productId}`}>
            <a className="flex w-full h-[345px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
              <figure className="flex flex-col">
                <div className="flex h-[250px]">
                  <img
                    // loader={() => product?.image?.path}
                    width={253}
                    height={200}
                    src={product?.image?.path}
                    objectFit="contain"
                    alt={product?.image?.seo?.alt}
                  />
                </div>
                <figcaption className="p-4 w-full">
                  <p className="mb-2 font-semibold text-sm text-grayScale05 min-h-[40px] line-clamp-2 ">
                    {product?.title}
                  </p>
                  <p className="mb-2 font-semibold text-theme-primary-main text-sm line-clamp-1">
                    Rp. {currencyFormatter(product?.price)}
                  </p>
                  <div className="flex flex-row items-center space-x-2 h-[32px]">
                    <LocationMarkerIcon width={15} height={15} className="text-greenSuccess" />
                    <p className="text-gray-600 text-xs break-words text-ellipsis w-full line-clamp-2">
                      {product?.sellerStore?.storeAddress?.districtName},{' '}
                      {product?.sellerStore?.storeAddress?.cityName}
                    </p>
                  </div>
                </figcaption>
              </figure>
            </a>
          </Link>
        </div>
      ))}
    </Slider>
  </div>
);

export const ProductDetailCarousel = ({ products }) => {
  const newCustomProductDetailSettings = { ...productDetailSettings };
  newCustomProductDetailSettings.customPaging = function (i) {
    return (
      <a>
        <img src={products[i].path} alt="" />
      </a>
    );
  };
  return (
    <>
      <Slider {...newCustomProductDetailSettings}>
        {products?.map((product, index) => {
          const { path, name } = product;
          return (
            <div key={index} className="mb-5 my-10 flex flex-col space-y-5 max-h-275 text-center">
              <img src={path} alt={name} className="mx-auto" />
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export const RelatedProductCarousel = () => (
  <div className="block">
    <Slider {...relatedProductSettings}>
      <div className="w-[225px]">
        <Link href="/product/1">
          <div className="flex w-[95%] h-[324px] mb-6 rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
            <figure className="flex flex-col">
              <div className="flex h-[250px]">
                <img
                  width={250}
                  height={200}
                  src="/assets/products-1.jpg"
                  objectFit="cover"
                  alt="products"
                />
              </div>
              <figcaption className="p-4 w-[92%]">
                <p className="mb-2 truncate font-semibold text-sm text-grayScale05">
                  Nama Barang max 2 baris asd
                </p>
                <div className="mb-5 flex justify-between items-center space-x-2 text-sm">
                  <div className="flex flex-row space-x-2">
                    <p className="mb-0 font-light text-grayScale04 line-through">Rp. 15.000</p>
                    <p className="mb-0 font-semibold text-theme-primary-main">Rp. 5.000</p>
                  </div>
                  <div className="flex w-[37px] h-[25px] bg-theme-primary-main rounded-md items-center justify-center">
                    <p className="m-0 font-semibold text-white text-[0.625rem]">- 33%</p>
                  </div>
                </div>
                <div className="mb-1 flex flex-row items-center space-x-2">
                  <ArchiveIcon width={15} height={15} className="text-greenSuccess" />
                  <p className="truncate text-gray-600 text-xs">Kelapa Gading, Jakarta Utara</p>
                </div>
              </figcaption>
            </figure>
          </div>
        </Link>
      </div>
      <div className="w-[225px]">
        <Link href="/product/1">
          <div className="flex w-[95%] h-[324px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
            <figure className="flex flex-col">
              <div className="flex h-[250px]">
                <img
                  width={250}
                  height={200}
                  src="/assets/products-1.jpg"
                  objectFit="cover"
                  alt="products"
                />
              </div>
              <figcaption className="p-4 w-[92%]">
                <p className="mb-2 truncate font-semibold text-sm text-grayScale05">
                  Nama Barang max 2 baris asd
                </p>
                <div className="mb-5 flex justify-between items-center space-x-2 text-sm">
                  <div className="flex flex-row space-x-2">
                    <p className="mb-0 font-light text-grayScale04 line-through">Rp. 15.000</p>
                    <p className="mb-0 font-semibold text-theme-primary-main">Rp. 5.000</p>
                  </div>
                  <div className="flex w-[37px] h-[25px] bg-theme-primary-main rounded-md items-center justify-center">
                    <p className="m-0 font-semibold text-white text-[0.625rem]">- 33%</p>
                  </div>
                </div>
                <div className="mb-1 flex flex-row items-center space-x-2">
                  <ArchiveIcon width={15} height={15} className="text-greenSuccess" />
                  <p className="truncate text-gray-600 text-xs">Kelapa Gading, Jakarta Utara</p>
                </div>
              </figcaption>
            </figure>
          </div>
        </Link>
      </div>
      <div className="w-[225px]">
        <Link href="/product/1">
          <div className="flex w-[95%] h-[324px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
            <figure className="flex flex-col">
              <div className="flex h-[250px]">
                <img
                  width={250}
                  height={200}
                  src="/assets/products-1.jpg"
                  objectFit="cover"
                  alt="products"
                />
              </div>
              <figcaption className="p-4 w-[92%]">
                <p className="mb-2 truncate font-semibold text-sm text-grayScale05">
                  Nama Barang max 2 baris asd
                </p>
                <div className="mb-5 flex justify-between items-center space-x-2 text-sm">
                  <div className="flex flex-row space-x-2">
                    <p className="mb-0 font-light text-grayScale04 line-through">Rp. 15.000</p>
                    <p className="mb-0 font-semibold text-theme-primary-main">Rp. 5.000</p>
                  </div>
                  <div className="flex w-[37px] h-[25px] bg-theme-primary-main rounded-md items-center justify-center">
                    <p className="m-0 font-semibold text-white text-[0.625rem]">- 33%</p>
                  </div>
                </div>
                <div className="mb-1 flex flex-row items-center space-x-2">
                  <ArchiveIcon width={15} height={15} className="text-greenSuccess" />
                  <p className="truncate text-gray-600 text-xs">Kelapa Gading, Jakarta Utara</p>
                </div>
              </figcaption>
            </figure>
          </div>
        </Link>
      </div>
      <div className="w-[225px]">
        <Link href="/product/1">
          <div className="flex w-[95%] h-[324px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
            <figure className="flex flex-col">
              <div className="flex h-[250px]">
                <img
                  width={250}
                  height={200}
                  src="/assets/products-1.jpg"
                  objectFit="cover"
                  alt="products"
                />
              </div>
              <figcaption className="p-4 w-[92%]">
                <p className="mb-2 truncate font-semibold text-sm text-grayScale05">
                  Nama Barang max 2 baris asd
                </p>
                <div className="mb-5 flex justify-between items-center space-x-2 text-sm">
                  <div className="flex flex-row space-x-2">
                    <p className="mb-0 font-light text-grayScale04 line-through">Rp. 15.000</p>
                    <p className="mb-0 font-semibold text-theme-primary-main">Rp. 5.000</p>
                  </div>
                  <div className="flex w-[37px] h-[25px] bg-theme-primary-main rounded-md items-center justify-center">
                    <p className="m-0 font-semibold text-white text-[0.625rem]">- 33%</p>
                  </div>
                </div>
                <div className="mb-1 flex flex-row items-center space-x-2">
                  <ArchiveIcon width={15} height={15} className="text-greenSuccess" />
                  <p className="truncate text-gray-600 text-xs">Kelapa Gading, Jakarta Utara</p>
                </div>
              </figcaption>
            </figure>
          </div>
        </Link>
      </div>
      <div className="w-[225px]">
        <Link href="/product/1">
          <div className="flex w-[95%] h-[324px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
            <figure className="flex flex-col">
              <div className="flex h-[250px]">
                <img
                  width={250}
                  height={200}
                  src="/assets/products-1.jpg"
                  objectFit="cover"
                  alt="products"
                />
              </div>
              <figcaption className="p-4 w-[92%]">
                <p className="mb-2 truncate font-semibold text-sm text-grayScale05">
                  Nama Barang max 2 baris asd
                </p>
                <div className="mb-5 flex justify-between items-center space-x-2 text-sm">
                  <div className="flex flex-row space-x-2">
                    <p className="mb-0 font-light text-grayScale04 line-through">Rp. 15.000</p>
                    <p className="mb-0 font-semibold text-theme-primary-main">Rp. 5.000</p>
                  </div>
                  <div className="flex w-[37px] h-[25px] bg-theme-primary-main rounded-md items-center justify-center">
                    <p className="m-0 font-semibold text-white text-[0.625rem]">- 33%</p>
                  </div>
                </div>
                <div className="mb-1 flex flex-row items-center space-x-2">
                  <ArchiveIcon width={15} height={15} className="text-greenSuccess" />
                  <p className="truncate text-gray-600 text-xs">Kelapa Gading, Jakarta Utara</p>
                </div>
              </figcaption>
            </figure>
          </div>
        </Link>
      </div>
      <div className="w-[225px]">
        <Link href="/product/1">
          <div className="flex w-[95%] h-[324px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
            <figure className="flex flex-col">
              <div className="flex h-[250px]">
                <img
                  width={250}
                  height={200}
                  src="/assets/products-1.jpg"
                  objectFit="cover"
                  alt="products"
                />
              </div>
              <figcaption className="p-4 w-[92%]">
                <p className="mb-2 truncate font-semibold text-sm text-grayScale05">
                  Nama Barang max 2 baris asd
                </p>
                <div className="mb-5 flex justify-between items-center space-x-2 text-sm">
                  <div className="flex flex-row space-x-2">
                    <p className="mb-0 font-light text-grayScale04 line-through">Rp. 15.000</p>
                    <p className="mb-0 font-semibold text-theme-primary-main">Rp. 5.000</p>
                  </div>
                  <div className="flex w-[37px] h-[25px] bg-theme-primary-main rounded-md items-center justify-center">
                    <p className="m-0 font-semibold text-white text-[0.625rem]">- 33%</p>
                  </div>
                </div>
                <div className="mb-1 flex flex-row items-center space-x-2">
                  <ArchiveIcon width={15} height={15} className="text-greenSuccess" />
                  <p className="truncate text-gray-600 text-xs">Kelapa Gading, Jakarta Utara</p>
                </div>
              </figcaption>
            </figure>
          </div>
        </Link>
      </div>
      <div className="w-[225px]">
        <Link href="/product/1">
          <div className="flex w-[95%] h-[324px] rounded-2.5xl overflow-hidden shadow-lg z-10 bg-white cursor-pointer">
            <figure className="flex flex-col">
              <div className="flex h-[250px]">
                <img
                  width={250}
                  height={200}
                  src="/assets/products-1.jpg"
                  objectFit="cover"
                  alt="products"
                />
              </div>
              <figcaption className="p-4 w-[92%]">
                <p className="mb-2 truncate font-semibold text-sm text-grayScale05">
                  Nama Barang max 2 baris asd
                </p>
                <div className="mb-5 flex justify-between items-center space-x-2 text-sm">
                  <div className="flex flex-row space-x-2">
                    <p className="mb-0 font-light text-grayScale04 line-through">Rp. 15.000</p>
                    <p className="mb-0 font-semibold text-theme-primary-main">Rp. 5.000</p>
                  </div>
                  <div className="flex w-[37px] h-[25px] bg-theme-primary-main rounded-md items-center justify-center">
                    <p className="m-0 font-semibold text-white text-[0.625rem]">- 33%</p>
                  </div>
                </div>
                <div className="mb-1 flex flex-row items-center space-x-2">
                  <ArchiveIcon width={15} height={15} className="text-greenSuccess" />
                  <p className="truncate text-gray-600 text-xs">Kelapa Gading, Jakarta Utara</p>
                </div>
              </figcaption>
            </figure>
          </div>
        </Link>
      </div>
    </Slider>
  </div>
);

export const ExploreCategoryCarousel = ({ categories }) => (
  <>
    <Slider {...exploreCategorySettings}>
      {categories ? (
        categories.map((category, index) => {
          const { icon, title } = category;
          return (
            <div key={index} className="flex flex-col space-y-5 text-center">
              <img src={icon} alt={title} className="mx-auto" />
              <p className="text-base text-gray-800">{title}</p>
            </div>
          );
        })
      ) : (
        <div></div>
      )}
    </Slider>
  </>
);

export const CategoryHeaderCarousel = ({ categories }) => (
  <>
    <Slider {...categoryCarouselSettings}>
      {categories?.map((category, index) => {
        const { name } = category;
        return (
          <div key={index} className="h-28">
            <Tab
              key={category.id}
              className={({ selected }) =>
                clsx(
                  'p-6 h-[95px] overflow-hidden justify-center text-center items-center space-y-5 border rounded-[0.625rem] text-grayScale05 w-11/12 hover:bg-theme-primary-lighter hover:bg-opacity-25 hover:border-theme-primary-main cursor-pointer transition-colors shadow-md',
                  selected &&
                    'border-theme-primary-main bg-theme-primary-lighter bg-opacity-25 cursor-pointer transition-colors outline-none',
                )
              }
            >
              <p className="text-base font-semibold line-clamp-2">{name}</p>
            </Tab>
          </div>
        );
      })}
    </Slider>
  </>
);
