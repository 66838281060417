import create from 'zustand';

const useCategoryStore = create((set, get) => ({
  categories: [],
  setCategories: (categories) => set({ categories, date: new Date() }),
  reset: () => set({ categories: [] }),
  getCategoryById: (categoryId) => {
    const id = Number(categoryId);
    const { categories } = get();
    let category = null;

    if (categories?.length > 0) {
      const level1 = categories.find((item) => item.id === id);

      if (level1) {
        category = level1;
      } else {
        for (let i = 0; i < categories.length; i += 1) {
          const level2 = categories[i].childs.find((child1) => child1.id === id);
          if (level2) {
            category = level2;
            break;
          } else {
            for (let j = 0; j < categories[i].childs.length; j += 1) {
              const level3 = categories[i].childs[j].childs.find((child2) => child2.id === id);
              if (level3) {
                category = level3;
                break;
              }
            }
          }
        }
      }
    }

    return category;
  },
}));

export default useCategoryStore;
