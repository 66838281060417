import clsx from 'clsx';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseCircleOutlined } from '@ant-design/icons';

const StickyFooterBanner = ({ imgUrl = null }) => {
  // const [opacity, setOpacity] = useState(0);
  const [latestScrollY, setLatestScrollY] = useState(0);
  // const [isSticky, setIsSticky] = useState(false);
  // const [closed, setClosed] = useState(false);

  const reference = useRef();
  const elementObserver = useRef(null);

  // const handleSetLatestScrollY = useCallback(
  //   debounce((value) => {
  //     setLatestScrollY(value);
  //   }, 800),
  //   [],
  // );

  // const onVisible = useCallback((element) => {
  //   if (element) {
  //     if (!elementObserver.current) {
  //       elementObserver.current = new IntersectionObserver(
  //         ([e]) => {
  //           setIsSticky(e.intersectionRatio < 1);
  //         },
  //         { threshold: [1] },
  //       );
  //     }

  //     elementObserver.current.observe(element);
  //   }
  // }, []);

  // const handleScroll = useCallback(() => {
  //   handleSetLatestScrollY(window.scrollY);
  //   if (window.scrollY > latestScrollY && window.scrollY >= 100) {
  //     setOpacity(1);
  //   } else {
  //     setOpacity(0);
  //   }
  // }, [latestScrollY, handleSetLatestScrollY]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   onVisible(reference.current);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     elementObserver.current = null;
  //   };
  // }, []);

  return (
    <section
      ref={reference}
      // className={clsx(!closed ? 'sticky' : 'static', ' z-30 mt-0', isSticky ? 'isSticky' : '')}
      // style={{ opacity, bottom: '-1px', paddingBottom: '1px' }}
      className={clsx('static', 'z-30 mt-6', 'max-w-screen-xl mx-auto')}
      style={{ opacity: 1, bottom: '-1px', paddingBottom: '1px' }}
    >
      {/* <div
        eslint-disable-next-line max-len
        className={clsx('relative h-[100px] sm:h-[120px] md:h-[140px] lg:h-[160px] xl:h-[180px]')}
      > */}
      <div>
        {/* {isSticky && !closed && (
          <span
            tabIndex="0"
            role="button"
            className="absolute right-3 sm:right-[120px] md:right-[240px] lg:right-[340px] rounded-full bg-white h-[32px] w-[32px] flex justify-center items-center shadow-lg"
            onClick={() => {
              setClosed(true);
            }}
          >
            <CloseCircleOutlined />
          </span>
        )} */}
        <img src={imgUrl} alt="..." className="w-full h-auto" />
      </div>
      {/* </div> */}
    </section>
  );
};

StickyFooterBanner.propTypes = {
  imgUrl: PropTypes.string,
};

export default StickyFooterBanner;
