import { useMemo } from 'react';
import Link from 'next/link';
import { CONFIG_SITE } from 'constants/index';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import StickyFooterBanner from './StickyFooterBanner';
import SocialMediaLinks from './SocialMediaLinks';
import { isMobile } from 'react-device-detect';

const Footer = ({ isHomepage = false, isLayoutDisabled = false }) => {
  const isXooply = CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.xooply;

  const d = new Date();
  const year = d.getFullYear();

  const templateSubject = `Kendala%20${CONFIG_SITE?.CONTENT?.COMPANY?.DOMAIN}`;
  const templateMessage = `Halo%20${CONFIG_SITE?.CONTENT?.COMPANY?.BRAND},%20bisa%20bantu%20saya?%0D%0ANama%20:%20%0D%0AE-mail%20:%20%0D%0AMasalah%20saya%20:%20`;

  const contacts = useMemo(() => {
    const list = [];

    if (CONFIG_SITE?.CONTENT?.CONTACTS?.WHATSAPP?.NUMBER) {
      list.push({
        id: 'whatsapp',
        text: CONFIG_SITE?.CONTENT?.CONTACTS?.WHATSAPP?.NUMBER,
        href: `${CONFIG_SITE?.CONTENT?.CONTACTS?.WHATSAPP?.LINK}?text=${templateMessage}`,
        iconImg: '/assets/icons/contact-whatsapp-footer.svg',
      });
    }
    if (CONFIG_SITE?.CONTENT?.CONTACTS?.TELEGRAM.USERNAME) {
      list.push({
        id: 'telegram',
        text: CONFIG_SITE?.CONTENT?.CONTACTS?.TELEGRAM.USERNAME,
        href: CONFIG_SITE?.CONTENT?.CONTACTS?.TELEGRAM.LINK,
        iconImg: '/assets/icons/contact-telegram-footer.svg',
      });
    }
    if (CONFIG_SITE?.CONTENT?.CONTACTS?.EMAIL?.SUPPORT) {
      list.push({
        id: 'email',
        text: CONFIG_SITE?.CONTENT?.CONTACTS?.EMAIL?.SUPPORT,
        href: `mailto:${CONFIG_SITE?.CONTENT?.CONTACTS?.EMAIL?.SUPPORT}?subject=${templateSubject}&body=${templateMessage}`,
        iconImg: '/assets/icons/contact-email-footer.svg',
      });
    }

    return list;
  }, []);

  return (
    <>
      {isHomepage && CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.idfood && (
        <StickyFooterBanner imgUrl="/assets/images/idfood-homepage-static-footer.png" />
      )}
      <footer className="bg-white shadow-2xl w-full px-3 md:px-5 relative">
        <div className="py-[40px] max-w-screen-xl px-9 mx-auto">
          <Row gutter={[24, 24]}>
            <Col xs={24} md={24} lg={8}>
              <Link href="/">
                <a className="w-auto h-[40px] relative mr-7 flex mb-4">
                  <img src={CONFIG_SITE.LOGO_URL} alt="Logo" className="w-auto h-full" />
                </a>
              </Link>
              <p className="font-normal text-grayScale04 text-sm text-justify">
                {CONFIG_SITE?.CONTENT?.FOOTER?.SUMMARY}
              </p>
            </Col>
            <Col xs={24} md={12} lg={4}>
              <div className="pl-0 xl:pl-[40px]">
                <h6 className="mb-4 text-lg font-semibold text-grayScale05">Perusahaan</h6>
                <ul className="list list-none flex flex-col space-y-2 text-grayScale04 font-normal text-sm">
                  <li>
                    <Link href="/about">
                      <a
                        tabIndex={0}
                        role="link"
                        className={
                          isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                        }
                        onClick={(e) => {
                          if (isLayoutDisabled) {
                            e.preventDefault();
                          }
                        }}
                      >
                        Tentang Kami
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/contact-us">
                      <a
                        tabIndex={0}
                        role="link"
                        className={
                          isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                        }
                        onClick={(e) => {
                          if (isLayoutDisabled) {
                            e.preventDefault();
                          }
                        }}
                      >
                        Kontak
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy-policy">
                      <a
                        tabIndex={0}
                        role="link"
                        className={
                          isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                        }
                        onClick={(e) => {
                          if (isLayoutDisabled) {
                            e.preventDefault();
                          }
                        }}
                      >
                        {isXooply ? 'Kebijakan Privasi' : 'Privacy Policy'}
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/terms-and-conditions">
                      <a className="hover:text-theme-primary-main">
                        {isXooply ? 'Syarat & Ketentuan' : 'Terms & Condition'}
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={24} md={12} lg={4}>
              <h6 className="mb-4 text-lg font-semibold grayScale04">Informasi</h6>
              <ul className="list list-none flex flex-col space-y-2 text-grayScale04 font-normal text-sm">
                <li>
                  <a
                    className={
                      isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                    }
                    href={
                      CONFIG_SITE?.CONTENT?.DOCUMENT_PANDUAN_PEMBELI_URL ||
                      '/assets/files/Panduan Pembeli.pdf'
                    }
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      if (isLayoutDisabled) {
                        e.preventDefault();
                      }
                    }}
                  >
                    Panduan Pembeli
                  </a>
                </li>
                {CONFIG_SITE?.WHITELABEL_KEY !== WHITELABEL_KEYS.idfood && (
                  <li>
                    <a
                      className={
                        isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                      }
                      href={
                        CONFIG_SITE?.CONTENT?.DOCUMENT_PANDUAN_PENJUAL_URL ||
                        '/assets/files/Panduan Penjual.pdf'
                      }
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        if (isLayoutDisabled) {
                          e.preventDefault();
                        }
                      }}
                    >
                      Menjadi Penyedia
                    </a>
                  </li>
                )}
                <li>
                  <Link href="/panduan/cara-bayar">
                    <a
                      tabIndex={0}
                      role="link"
                      className={
                        isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                      }
                      onClick={(e) => {
                        if (isLayoutDisabled) {
                          e.preventDefault();
                        }
                      }}
                    >
                      Informasi Pembayaran
                    </a>
                  </Link>
                </li>
              </ul>
            </Col>
            {isXooply ? (
              <Col xs={24} sm={12} lg={8}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <h6 className="mb-4 text-lg font-semibold text-grayScale05">Hubungi Kami</h6>
                    <p className="mb-4 text-justify text-sm text-grayScale04">
                      {CONFIG_SITE?.CONTENT?.ADDRESS_LIST.join(', ').replace(/,,/g, ',')}
                    </p>
                    <div className="mb-6">
                      {contacts.length > 0 && (
                        <ul className="flex flex-col gap-2">
                          {contacts.map((contact) => (
                            <li key={contact.id}>
                              <a
                                className={`font-semibold flex items-center ${
                                  isLayoutDisabled
                                    ? 'cursor-not-allowed'
                                    : 'hover:text-theme-primary-main'
                                }`}
                                href={contact.href}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e) => {
                                  if (isLayoutDisabled) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                <span className="h-[22px] w-[22px]">
                                  <img
                                    src={contact.iconImg}
                                    alt="contact-whatsapp"
                                    className="h-full w-auto object-center object-cover mr-2 "
                                  />
                                </span>
                                <span className="ml-1 text-xs">{contact.text}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <SocialMediaLinks isLayoutDisabled={isLayoutDisabled} withoutTitle />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col xs={24} sm={12} lg={6}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <h6 className="mb-4 text-lg font-semibold text-grayScale05">Alamat</h6>
                    <p className="text-justify text-sm text-grayScale04">
                      {CONFIG_SITE?.CONTENT?.ADDRESS_LIST.join(', ').replace(/,,/g, ',')}
                    </p>
                  </Col>
                  <Col span={24}>
                    <div>
                      {contacts.length > 0 && (
                        <>
                          <h6 className="mb-3 text-lg font-semibold text-grayScale05">Kontak</h6>
                          <ul className="flex flex-col gap-2">
                            {contacts.map((contact) => (
                              <li key={contact.id}>
                                <a
                                  className={`font-semibold flex items-center ${
                                    isLayoutDisabled
                                      ? 'cursor-not-allowed'
                                      : 'hover:text-theme-primary-main'
                                  }`}
                                  href={contact.href}
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={(e) => {
                                    if (isLayoutDisabled) {
                                      e.preventDefault();
                                    }
                                  }}
                                >
                                  <span className="h-[22px] w-[22px]">
                                    <img
                                      src={contact.iconImg}
                                      alt="contact-whatsapp"
                                      className="h-full w-auto object-center object-cover mr-2 "
                                    />
                                  </span>
                                  <span className="ml-1 text-xs">{contact.text}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <div className="my-14" />
          {isXooply && (
            <>
              <Row gutter={[24, 48]}>
                <Col xs={24} md={8}>
                  <div className="px-0 flex flex-col gap-1">
                    <h3>Tergabung di</h3>
                    <div className="mt-3 flex flex-wrap gap-10">
                      {(CONFIG_SITE?.CONTENT?.FOOTER?.JOINED_COMPANY_LOGO_URLS ?? []).map(
                        (item, index) => (
                          <div key={`joined-company-logo-url-${index}`} className="self-center">
                            <img src={item} alt="" className="object-contain object-center max-h-[32px] max-w-[80px]" />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div className="px-0 flex flex-col gap-1">
                    <h3>Pembayaran</h3>
                    <div className="mt-3 flex flex-wrap gap-10">
                      {(CONFIG_SITE?.CONTENT?.FOOTER?.SUPPORTED_PAYMENT_LOGO_URLS ?? []).map(
                        (item, index) => (
                          <div key={`joined-company-logo-url-${index}`} className="self-center">
                            <img src={item} alt="" className="object-contain object-center max-h-[32px] max-w-[80px]" />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div className="px-0 flex flex-col gap-1">
                    <h3>Pengiriman</h3>
                    <div className="mt-3 flex flex-wrap gap-10">
                      {(CONFIG_SITE?.CONTENT?.FOOTER?.SUPPORTED_LOGISTIC_LOGO_URLS ?? []).map(
                        (item, index) => (
                          <div key={`joined-company-logo-url-${index}`} className="self-center">
                            <img src={item} alt="" className="object-contain object-center max-h-[32px] max-w-[80px]" />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="mt-14">
                <Col xs={24} md={8}>
                  <div className="px-0 flex flex-col gap-1">
                    <h3>Standards & Certifications</h3>
                    <div className="mt-3 flex flex-wrap gap-10">
                      {(CONFIG_SITE?.CONTENT?.FOOTER?.STANDARDS_AND_CERTIFICATIONS ?? []).map(
                        (item, index) => (
                          <div key={`joined-company-logo-url-${index}`} className="self-center">
                            <img src={item} alt="" className="object-contain object-center max-h-[32px] max-w-[80px]" />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="pb-10 lg:mb-0" />
            </>
          )}
        </div>
        <div className="flex flex-row py-3 border-t">
          <div className="container mx-auto text-center text-grayScale05 text-xs lg:text-md">
            <p>
              Copyright © {year} <strong>{CONFIG_SITE?.CONTENT?.COMPANY?.NAME ?? ''}.</strong> All
              rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  isHomepage: PropTypes.bool,
};

export default Footer;
